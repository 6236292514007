<template>
	<div class="navbar navBar-wrapper">
		<div class="mcontainer navBar-container">
			<div class="mrow flex wrap align-center buttonsBlock">
				<div class="menu-block burger-button-container md-hide">
					<button
						type="button"
						class="navbar-toggle"
						@click="emitEvent('toggleSidebar')"
					>
						<i class="icomoon icon-cross" />
					</button>
				</div>

				<transition name="standard-fade" mode="out-in">
					<div
						v-if="navbarSettings.showCreateButtonForTableForm"
						class="menu-block buttonWrapper"
					>
						<el-button
							type="secondary"
							native-type="button"
							:class="'shadow'"
							@click="emitEvent('createItem')"
							v-text="'Создать'"
						/>
						<!-- </el-button> -->
					</div>
				</transition>

				<transition name="standard-fade" mode="out-in">
					<div
						v-if="navbarSettings.showCreateButton"
						class="menu-block buttonWrapper"
					>
						<el-button
							type="secondary"
							native-type="button"
							:class="'shadow bold'"
							@click="changeRoute({ path: '/new', addToCurrent: true })"
							v-text="'Создать'"
						>
							<!-- icon="icomoon icon-plus" -->
							<!-- <span v-if="navbarSettings.showCreateButton.text">{{ navbarSettings.showCreateButton.text }}</span> -->
						</el-button>
					</div>
				</transition>

				<transition name="standard-fade" mode="out-in">
					<div
						v-if="navbarSettings.showDeleteButton"
						class="menu-block buttonWrapper"
					>
						<el-button
							type="secondary"
							native-type="button"
							plain
							:class="'shadow delete-button bold'"
							@click="emitEvent('deleteItems')"
							v-text="'Удалить'"
						/>
						<!-- icon="icomoon icon-cross delete" -->
					</div>
				</transition>
				<transition name="standard-fade" mode="out-in">
					<div v-if="navbarSettings.showSaveButton" class="menu-block buttonWrapper">
						<el-button
							:class="'shadow save-button bold'"
							type="secondary"
							native-type="button"
							:loading="saving"
							@click="emitEvent('saveItem')"
							v-text="'Сохранить'"
						/>
					</div>
				</transition>

				<transition name="standard-fade" mode="out-in">
					<div
						v-if="navbarSettings.showCloseButton"
						class="menu-block buttonWrapper"
					>
						<el-button
							plain
							type="secondary"
							native-type="button"
							class="shadow bold"
							@click="changeRoute({ parent: true })"
							v-text="'Закрыть'"
						/>
						<!-- icon="icomoon icon-X" -->
					</div>
				</transition>

				<transition name="standard-fade" mode="out-in">
					<div v-if="navbarSettings.navigateButton" class="menu-block buttonWrapper">
						<el-button
							:class="'shadow save-button'"
							type="secondary"
							native-type="button"
							icon="icomoon icon-path_2"
							@click="changeRoute(navbarSettings.navigateButton)"
						/>
					</div>
				</transition>

				<transition name="standard-fade" mode="out-in">
					<div v-if="navbarSettings.editButton" class="menu-block buttonWrapper">
						<el-button
							:class="'shadow save-button'"
							type="secondary"
							native-type="button"
							icon="icomoon icon-pencil"
							@click="changeRoute(navbarSettings.editButton)"
						/>
					</div>
				</transition>
				<transition name="standard-fade" mode="out-in">
					<div
						v-if="navbarSettings.showCleanButton"
						class="menu-block buttonWrapper"
					>
						<el-button
							type="secondary"
							native-type="button"
							plain
							:class="'shadow clean-button'"
							icon="icomoon icon-clean"
							@click="emitEvent('cleanForm')"
						/>
					</div>
				</transition>

				<!-- <transition name="standard-fade" mode="out-in">
					<div class="menu-block filter-block mcol-xs-5 mcol-sm-3 text-right ml-auto" v-if="navbarSettings.showFilter">
						<SimpleSpinner :active="plantsLoading" />

						<el-select
							:class="{ isClient: authUser.plant_id }"
							clearable
							@change="id => setGlobalFilters({ id: id, filterName: 'plantId' })"
							:disabled="!!authUser.plant_id || !plantsList.length"
							:value="globalFilters.plantId"
							placeholder="Choose plant"
						>
							<el-option
								v-for="item in plantsList"
								:key="'globFilterItem-' + item.id"
								:label="item.name"
								:value="item.id"
							/>
						</el-select>
					</div>
				</transition> -->
			</div>
		</div>
	</div>
</template>

<script>
import { navigation } from '@/mixins';
import { mapActions, mapState } from 'vuex';

export default {
	mixins: [navigation],

	props: {
		navbarSettings: Object,
		currentPath: String,
		loading: Boolean,
		saving: Boolean
		// filters: Object
		/*routesLinks: {
			type: Array,
			default: ()=>[]
		}*/
	},

	computed: {
		...mapState({
			globalFilters: state => state.global.globalFilters,
			// plantsLoading: state => state.global.globalPlantsLoading,
			// plantsList: state => state.global.globalPlantsList,
			authUser: state => state.auth.authUser
		})
	},

	methods: {
		...mapActions({
			// set_global_filters: 'set_global_filters',
			set_global_filters: 'set_global_filters'
			// fetch_global_plants: 'fetch_global_plants'
		}),

		/*fetchPlants(filters) {
			const payload = { params: { ...filters } };
			this.fetch_global_plants(payload);
		},*/

		emitEvent(eventName, data) {
			this.$emit('event', eventName, data);
		},

		setGlobalFilters({ id, filterName }) {
			// let filter = {};
			// filter[filterName] = id;
			const newFilters = { ...this.globalFilters, [filterName]: id };
			// console.log(newFilters)
			this.set_global_filters(newFilters);
		}
	},

	created() {
		/*if (this.authUser && this.authUser.plant_id) {
			this.setGlobalFilters({ id: this.authUser.plant_id, filterName: 'plantId' });
		}*/
	},

	beforeDestroy() {
		// this.set_global_filters({ plantId: null, companyId: null });
	}
};
</script>
